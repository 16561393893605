<template>
  <div class="px-5">
    <div class="fs-20 fw-500 txt-grey-600 my-3">
      <span
        @click="handleBackSubOrgList"
        class="fs-18 fw-400 txt-grey-900 cursor-pointer"
      >{{ $t('lbl_list_of_the_branches') }}</span>
      <img
        class="mx-3"
        src="../../assets/images/keyboard_arrow_right.png"
        alt="keyboard_arrow_right"
      />
      <span class="fs-18 fw-400 txt-grey-900">{{org.name }}</span>
    </div>
    <div class="bg-white px-4 rounded-2xl pb-10 pt-4">
      <div class="border p-3 rounded-xl mb-4">
        <div class="row fs-17 text-black">
          <div class="col-10 mb-2">
            <span>{{ $t('lbl_org_name') }}: &nbsp;</span>
            <span class="font-bold">{{ org.name }}</span>
          </div>
          <div class="col-2 mb-2">
            <svg
              @click="$router.push({name:'OrganizationDetailUpdate',params:{id:orgId}})"
              class="float-right cursor-pointer"
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6201 9.00044C16.4212 9.00044 16.2304 9.07946 16.0898 9.22011C15.9491 9.36076 15.8701 9.55153 15.8701 9.75044V14.2504C15.8701 14.4494 15.7911 14.6401 15.6504 14.7808C15.5098 14.9214 15.319 15.0004 15.1201 15.0004H4.62012C4.4212 15.0004 4.23044 14.9214 4.08979 14.7808C3.94913 14.6401 3.87012 14.4494 3.87012 14.2504V3.75044C3.87012 3.55153 3.94913 3.36076 4.08979 3.22011C4.23044 3.07946 4.4212 3.00044 4.62012 3.00044H9.12012C9.31903 3.00044 9.50979 2.92142 9.65045 2.78077C9.7911 2.64012 9.87012 2.44935 9.87012 2.25044C9.87012 2.05153 9.7911 1.86076 9.65045 1.72011C9.50979 1.57946 9.31903 1.50044 9.12012 1.50044H4.62012C4.02338 1.50044 3.45108 1.7375 3.02913 2.15945C2.60717 2.58141 2.37012 3.15371 2.37012 3.75044V14.2504C2.37012 14.8472 2.60717 15.4195 3.02913 15.8414C3.45108 16.2634 4.02338 16.5004 4.62012 16.5004H15.1201C15.7169 16.5004 16.2892 16.2634 16.7111 15.8414C17.1331 15.4195 17.3701 14.8472 17.3701 14.2504V9.75044C17.3701 9.55153 17.2911 9.36076 17.1504 9.22011C17.0098 9.07946 16.819 9.00044 16.6201 9.00044ZM5.37012 9.57044V12.7504C5.37012 12.9494 5.44914 13.1401 5.58979 13.2808C5.73044 13.4214 5.9212 13.5004 6.12012 13.5004H9.30012C9.39882 13.501 9.49667 13.4821 9.58805 13.4448C9.67942 13.4074 9.76254 13.3525 9.83262 13.2829L15.0226 8.08544L17.1526 6.00044C17.2229 5.93072 17.2787 5.84777 17.3168 5.75638C17.3549 5.66498 17.3745 5.56695 17.3745 5.46794C17.3745 5.36893 17.3549 5.2709 17.3168 5.17951C17.2787 5.08812 17.2229 5.00516 17.1526 4.93544L13.9726 1.71794C13.9029 1.64765 13.8199 1.59185 13.7285 1.55377C13.6372 1.5157 13.5391 1.49609 13.4401 1.49609C13.3411 1.49609 13.2431 1.5157 13.1517 1.55377C13.0603 1.59185 12.9773 1.64765 12.9076 1.71794L10.7926 3.84044L5.58762 9.03794C5.51811 9.10802 5.46311 9.19113 5.42579 9.28251C5.38847 9.37389 5.36955 9.47174 5.37012 9.57044ZM13.4401 3.30794L15.5626 5.43044L14.4976 6.49544L12.3751 4.37294L13.4401 3.30794ZM6.87012 9.87794L11.3176 5.43044L13.4401 7.55294L8.99262 12.0004H6.87012V9.87794Z"
                fill="#03267A"
              />
            </svg>
          </div>

          <div class="col-6 mb-2">
            <span class>{{ $t('lbl_governing_body') }}: &nbsp;</span>
            <span class="font-bold">{{ org.authority_in_charge }}</span>
          </div>
          <div class="col-6 mb-2">
            <span class="fs-17">{{ $t('lbl_director_president') }}: &nbsp;</span>
            <span class="font-bold">{{ org.owner ? org.owner.name : '' }}</span>
          </div>
          <div class="col-6">
            <span>{{ $t('lbl_hotline') }}: &nbsp;</span>
            <span>{{ org.contact_point }}</span>
          </div>
          <div class="col-6">
            <span>{{ $t('lbl_address') }}: &nbsp;</span>
            <span>{{ org.address }}</span>
          </div>
        </div>
      </div>

      <OrganizationTableWS :orgId="orgId" />
    </div>
  </div>
</template>
<script>
import OrganizationTableWS from '@/components/OrganizationTableWS.vue'

export default {
  name: 'OrganizationDetail',
  components: { OrganizationTableWS },
  data () {
    return {
      org: {},
      orgId: 0
    }
  },
  beforeMount () {
    this.orgId = this.$route.params.id
  },
  mounted () {
    this.getOrg(this.orgId)
  },
  methods: {
    async getOrg (id) {
      if (!id) return
      const response = await this.$rf.getRequest('DoctorRequest').getOrg(id)

      this.org = response.data
    },
    handleBackSubOrgList () {
      this.$router.push({
        name: 'OrganizationManagement'
      })
    }
  }
}
</script>

  <style lang="scss" scoped>
::v-deep {
  .button-add {
    background-color: #20419b;
  }
  .select-form {
    appearance: none;
  }

  .arrow-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .el-select-owner {
    input {
      font-size: 18px !important;
      color: #41464b !important;
      font-weight: 400 !important;
      background-color: transparent !important;
      padding: 0 !important;
    }

    input::placeholder {
      font-size: 20px;
      color: #868181;
      font-weight: 400;
    }
  }

  .txt-grey-1000 {
    color: #52575c !important;
  }
}
</style>
